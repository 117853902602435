import { Alert, Slide, Snackbar } from "@mui/material";

export default function ValidationErrorAlert({
  showsAlert,
  setShowsAlert,
  alertText,
}: {
  showsAlert: boolean;
  setShowsAlert: (val: boolean) => void;
  alertText: string;
}) {
  const handleAlertHide = () => setShowsAlert(false);

  return (
    <Snackbar
      open={showsAlert}
      autoHideDuration={5000}
      onClose={handleAlertHide}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      TransitionComponent={Slide}
    >
      <Alert
        onClose={handleAlertHide}
        severity="warning"
        variant="filled"
        sx={{ width: 400 }}
      >
        {alertText}
      </Alert>
    </Snackbar>
  );
}
