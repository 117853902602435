import { useState } from "react";

export default function useHandleAlert() {
  const [showsAlert, setShowsAlert] = useState(false);
  const [alertText, setAlertText] = useState("");

  const handleAlertOpen = ({ errMessage }: { errMessage: string }) => {
    setAlertText(errMessage);
    setShowsAlert(true);
  };

  return {
    showsAlert,
    setShowsAlert,
    alertText,
    setAlertText,
    handleAlertOpen,
  };
}
