import { useContext } from "react";
import { Box } from "@mui/material";
import { useAtomValue } from "jotai";

import { GET_SHIPMENT_SCHEDULE_DETAIL_RES as ScheduleDetail } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";
import { FreightType } from "@sellernote/_shared/src/types/common/common";

import getScheduleTime from "../utils";
import { usePortOptionList } from "./hooks";

import { FORWARDING_ADMIN_TRELLO_ATOMS } from "../../../../../../../../jotaiStates/trello";
import { FormDisabledContext } from "../..";
import AddFormButton from "./AddFormButton";
import Arrival from "./Arrival";
import DeleteButton from "./DeleteButton";
import Departure from "./Departure";
import ShipName from "./ShipName";
import VoyageNumber from "./VoyageNumber";

export default function ScheduleInput({
  shipmentScheduleDetail,
  freightType,
  isConsolOrLCL,
}: {
  shipmentScheduleDetail: ScheduleDetail | undefined;
  freightType: FreightType;
  isConsolOrLCL: boolean;
}) {
  const isDisabled = useContext(FormDisabledContext);

  const shipmentScheduleList = useAtomValue(
    FORWARDING_ADMIN_TRELLO_ATOMS.SHIPMENT_SCHEDULE_LIST
  );

  const { scheduleUpdatedItems = [] } = shipmentScheduleDetail || {};

  const { portOptionList } = usePortOptionList();

  return (
    <Box sx={{ marginTop: "4px" }}>
      {shipmentScheduleList.map((route, index) => {
        const isFirstIndex = index === 0;
        const isLastIndex = index === shipmentScheduleList.length - 1;

        return (
          <Box key={index} style={{ display: "flex", flexDirection: "column" }}>
            <Box style={{ display: "flex", gap: 12 }}>
              {/** 출발지/ETA 폼 */}
              <Departure
                index={index}
                portOptionList={portOptionList}
                departureInfo={{
                  port: {
                    id: route.departurePort.id,
                    name: route.departurePort.name,
                    nameEN: route.departurePort.nameEN,
                  },
                  type: getScheduleTime({
                    ET: route.ETD,
                    AT: route.ATD,
                    isDeparture: true,
                  }).type,
                  date: getScheduleTime({
                    ET: route.ETD,
                    AT: route.ATD,
                    isDeparture: true,
                  }).date,
                }}
                isFormDisabled={isDisabled}
                /** 선사/마린트래픽으로부터 운송전체의 ETD가 변경된 경우 날짜 강조 표시, 환적지 제외 */
                highlightsDate={
                  scheduleUpdatedItems.includes("ETD") &&
                  (isFirstIndex || isLastIndex)
                }
              />

              {/** 도착지/ETD 폼 */}
              <Arrival
                index={index}
                isLastIndex={isLastIndex}
                portOptionList={portOptionList}
                arrivalInfo={{
                  port: {
                    id: route.arrivalPort.id,
                    name: route.arrivalPort.name,
                    nameEN: route.arrivalPort.nameEN,
                  },
                  type: getScheduleTime({ ET: route.ETA, AT: route.ATA }).type,
                  date: getScheduleTime({ ET: route.ETA, AT: route.ATA }).date,
                }}
                isFormDisabled={isDisabled}
                /** 선사/마린트래픽으로부터 운송전체의 ETA가 변경된 경우 날짜 강조 표시, 환적지는 제외 */
                highlightsDate={
                  scheduleUpdatedItems.includes("ETA") &&
                  (isFirstIndex || isLastIndex)
                }
              />

              {/** 모선명 */}
              <ShipName
                index={index}
                shipInfo={{
                  name: route.shipName,
                  IMO: route.shipIMO,
                }}
                isFormDisabled={isDisabled}
                /** 선사/마린트래픽으로부터 모선명이 변경된 경우 강조 표시, 환적지는 제외 */
                highlightsShipName={
                  scheduleUpdatedItems.includes("shipName") &&
                  (isFirstIndex || isLastIndex)
                }
                isAIR={freightType === "AIR"}
                isConsolOrLCL={isConsolOrLCL}
              />

              {/** 항차 */}
              <VoyageNumber
                index={index}
                voyageNo={route.voyageNo}
                isFormDisabled={isDisabled}
                /** 선사/마린트래픽으로부터 항차가 변경된 경우 강조 표시, 환적지는 제외 */
                highlightsVoyageNo={
                  scheduleUpdatedItems.includes("voyageNumber") &&
                  (isFirstIndex || isLastIndex)
                }
                isAIR={freightType === "AIR"}
              />

              {/** 폼 삭제 버튼(폼 우측) */}
              <DeleteButton
                index={index}
                /** 스케줄 입력 폼이 하나일 경우, 출발지 구간은 삭제 버튼을 노출하지 않음 */
                showsDeleteButton={
                  shipmentScheduleList.length > 1 && !isFirstIndex
                }
                isFormDisabled={isDisabled}
              />
            </Box>

            {/** 폼 추가 버튼(폼 하단) */}
            <AddFormButton
              index={index}
              /** 폼은 최대 5개까지 생성 가능, 마지막 항목은 '+' 버튼을 노출하지 않음 */
              showsAddFormButton={
                shipmentScheduleList.length < 6 || !isLastIndex
              }
              isFormDisabled={isDisabled}
            />
          </Box>
        );
      })}
    </Box>
  );
}
