import { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import { CHANGE_SCHEDULE_REASON_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/vesselSchedule";
import { ScheduleModifierType } from "@sellernote/_shared/src/types/forwarding/trello";

import useHandleShipmentSchedule from "../hooks/useHandleShipmentSchedule";

import AutoRenewalRadio from "./AutoRenewalRadio";

export default function ScheduleChangeReasonModalBody({
  shipmentId,
  onScheduleChangeModalClose,
  isFirstScheduleEmailSend,
  isSendEmail,
  ScheduleModifierType,
  onSnackbarOpen,
  onAlertOpen,
}: {
  shipmentId: number;
  onScheduleChangeModalClose: () => void;
  isFirstScheduleEmailSend: boolean;
  isSendEmail: boolean;
  ScheduleModifierType: ScheduleModifierType | undefined;
  onSnackbarOpen: () => void;
  onAlertOpen: ({ errMessage }: { errMessage: string }) => void;
}) {
  /** 모달 height을 동적으로 관리하기 위해 사용 */
  const [isOpen, setIsOpen] = useState(false);

  const isScheduleModifiedByMarineTrafficOrLiner =
    ScheduleModifierType === "liner" || ScheduleModifierType === "mt";

  const {
    keepsAutoRenewal,
    handleAutoRenewalUse,
    scheduleChangeReason,
    handleReasonUpdate,
    handleShipmentScheduleUpdate,
    ResponseHandlerOfUpdateShipmentSchedule,
  } = useHandleShipmentSchedule({
    isFirstScheduleEmailSend,
    isSendEmail,
    shipmentId,
    onScheduleChangeModalClose,
    onSnackbarOpen,
    onAlertOpen,
    isScheduleModifiedByMarineTrafficOrLiner,
  });

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: 450,
          height:
            isScheduleModifiedByMarineTrafficOrLiner || isOpen ? 350 : 230,
        }}
      >
        <Typography variant="h5" fontWeight={"bold"}>
          스케줄 수정 사유를 입력해주세요.
        </Typography>

        <Autocomplete
          disablePortal
          options={CHANGE_SCHEDULE_REASON_OPTION_LIST}
          renderInput={(params) => (
            <TextField
              {...params}
              label="사유"
              onChange={(e) => handleReasonUpdate(e.target.value)}
            />
          )}
          value={scheduleChangeReason}
          style={{ marginTop: 24 }}
          onChange={(_, value) => handleReasonUpdate(value ?? "")}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        />

        <Typography variant="overline">
          위 사유는 화주에게 발송되는 스케줄 변경 안내 이메일에 포함됩니다.
        </Typography>

        {isScheduleModifiedByMarineTrafficOrLiner && (
          <AutoRenewalRadio
            keepsAutoRenewal={keepsAutoRenewal}
            onAutoRenewalUse={handleAutoRenewalUse}
          />
        )}

        <Button
          style={{ alignSelf: "flex-end", marginTop: 40 }}
          variant="contained"
          onClick={() => handleShipmentScheduleUpdate({})}
        >
          확인
        </Button>
      </Box>

      {ResponseHandlerOfUpdateShipmentSchedule}
    </>
  );
}
