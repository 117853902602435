import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useAtomValue } from "jotai";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import { ShipmentInfo } from "@sellernote/_shared/src/types/forwarding/trello";

import getScheduleTime from "../../utils";

import { FORWARDING_ADMIN_TRELLO_ATOMS } from "../../../../../../../../../jotaiStates/trello";

function useHandleShipmentSchedule({
  isFirstScheduleEmailSend,
  isSendEmail,
  shipmentId,
  projectStatus,
  isImport,
  onSnackbarOpen,
  onAlertOpen,
  onScheduleChangeModalClose,
  isScheduleModifiedByMarineTrafficOrLiner,
}: {
  isFirstScheduleEmailSend: boolean;
  isSendEmail: boolean;
  shipmentId: number;
  projectStatus?: BidProjectStatus;
  isImport?: boolean;
  onSnackbarOpen?: () => void;
  onAlertOpen: ({ errMessage }: { errMessage: string }) => void;
  onScheduleChangeModalClose?: () => void;
  isScheduleModifiedByMarineTrafficOrLiner?: boolean;
}) {
  const queryClient = useQueryClient();

  const {
    mutate: registerShipmentSchedule,
    ResponseHandler: ResponseHandlerOfRegisterShipmentSchedule,
  } = TRELLO_BID_QUERY.useRegisterShipmentSchedule();

  const {
    mutate: updateShipmentSchedule,
    ResponseHandler: ResponseHandlerOfUpdateShipmentSchedule,
  } = TRELLO_BID_QUERY.useUpdateShipmentSchedule();

  const shipmentScheduleList = useAtomValue(
    FORWARDING_ADMIN_TRELLO_ATOMS.SHIPMENT_SCHEDULE_LIST
  );

  /** 스케줄 수정 사유 */
  const [scheduleChangeReason, setScheduleChangeReason] = useState("");
  /** 자동 갱신 중단 여부 */
  const [keepsAutoRenewal, setKeepsAutoRenewal] = useState(false);

  const handleReasonUpdate = (reason: string) => {
    setScheduleChangeReason(reason);
  };

  const handleAutoRenewalUse = (checked: boolean) => {
    setKeepsAutoRenewal(checked);
  };

  /** 스케줄 변경 API 요청 인터페이스에 맞게 shipmentScheduleList 구조를 변환 */
  const scheduleList: ShipmentInfo[] = useMemo(() => {
    return shipmentScheduleList.map((form) => {
      return {
        /** 출발지 정보 */
        departurePortId: form.departurePort.id,
        departureDate:
          getScheduleTime({ ET: form.ETD, AT: form.ATD }).date ??
          form.ETD ??
          "",
        /** 도착지 정보 */
        arrivalPortId: form.arrivalPort.id,
        arrivalDate:
          getScheduleTime({ ET: form.ETA, AT: form.ATA }).date ??
          form.ETA ??
          "",
        /** 선박 정보 */
        shipIMO: form.shipIMO,
        shipName: form.shipName,
        voyageNo: form.voyageNo,
      };
    });
  }, [shipmentScheduleList]);

  const handleShipmentScheduleUpdate = useCallback(
    ({
      isRegisterScheduleSendsEmail,
    }: {
      isRegisterScheduleSendsEmail?: boolean;
    }) => {
      /** 스케줄 변경인데, 사유가 없는 경우 경고 */
      if (isFirstScheduleEmailSend && !scheduleChangeReason) {
        onAlertOpen({ errMessage: "스케줄 변경 사유를 입력해주세요." });

        return;
      }

      /** 스케줄 최초입력, 변경에 필요한 요청 공통 payload */
      const payload = {
        routes: scheduleList,
        sendEmail: isFirstScheduleEmailSend
          ? isSendEmail
          : Boolean(isRegisterScheduleSendsEmail),
        pathParams: { shipmentId },
      };

      /** 스케줄 변경 성공시 데이터 갱신, 모달 닫음 */
      const handleSuccess = () => {
        onScheduleChangeModalClose && onScheduleChangeModalClose();

        /** 성공시 스낵바 노출 */
        onSnackbarOpen && onSnackbarOpen();

        return Promise.all([
          queryClient.invalidateQueries(
            TRELLO_BID_QUERY_KEY_GEN.getShipmentScheduleDetail({ shipmentId })
          ),
          queryClient.invalidateQueries(
            TRELLO_BID_QUERY_KEY_GEN.trelloDetail()
          ),
        ]);
      };

      /** 스케줄 변경의 경우, 스케줄 변경 사유와 자동 갱신 여부를 payload에 추가 */
      if (isFirstScheduleEmailSend) {
        updateShipmentSchedule(
          {
            ...payload,
            scheduleChangeReason,
            /** 마린트래픽 또는 선사에 의해 스케줄이 수정된 경우, 자동 갱신 중단 여부를 Payload에 추가 */
            ...(isScheduleModifiedByMarineTrafficOrLiner
              ? { inActivate: !keepsAutoRenewal }
              : []),
          },
          { onSuccess: handleSuccess }
        );

        return;
      }

      /** 수입운송은 스케줄 컨택 중일 때만 스케줄 입력이 가능 */
      if (isImport && projectStatus !== "contactingPartner") {
        onAlertOpen({
          errMessage: "스케줄 컨택 중인 경우 스케줄 입력이 가능합니다.",
        });

        return;
      }

      /** 수출운송은 화물준비 중일 때만 스케줄 입력이 가능 */
      if (!isImport && projectStatus !== "waitingFreight") {
        onAlertOpen({
          errMessage: "화물 준비 중인 경우 스케줄 입력이 가능합니다.",
        });

        return;
      }

      /** 스케줄 최초 입력의 경우 */
      registerShipmentSchedule(payload, { onSuccess: handleSuccess });
    },
    [
      isFirstScheduleEmailSend,
      scheduleChangeReason,
      scheduleList,
      isSendEmail,
      shipmentId,
      projectStatus,
      isImport,
      registerShipmentSchedule,
      onAlertOpen,
      onScheduleChangeModalClose,
      onSnackbarOpen,
      queryClient,
      updateShipmentSchedule,
      isScheduleModifiedByMarineTrafficOrLiner,
      keepsAutoRenewal,
    ]
  );

  return {
    scheduleChangeReason,
    handleReasonUpdate,
    keepsAutoRenewal,
    handleAutoRenewalUse,
    scheduleList,
    handleShipmentScheduleUpdate,
    ResponseHandlerOfRegisterShipmentSchedule,
    ResponseHandlerOfUpdateShipmentSchedule,
  };
}

export default useHandleShipmentSchedule;
