import { Box, Button } from "@mui/material";

import { glowing } from "./animation";

interface ButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  showsAnimation?: boolean;
}

export default function SaveButtonGroup({
  saveButton,
  saveAndEmailButton,
}: {
  saveButton: ButtonProps;
  saveAndEmailButton: ButtonProps;
}) {
  return (
    <Box style={{ alignSelf: "flex-end" }}>
      <Button
        variant="outlined"
        onClick={saveButton.onClick}
        disabled={saveButton.isDisabled}
        sx={{
          mr: 1,
          animation: saveButton.showsAnimation
            ? `${glowing} 1.4s linear infinite`
            : "none",
        }}
      >
        저장
      </Button>

      <Button
        variant="outlined"
        onClick={saveAndEmailButton.onClick}
        disabled={saveAndEmailButton.isDisabled}
        sx={{
          animation: saveAndEmailButton.showsAnimation
            ? `${glowing} 1.4s linear infinite`
            : "none",
        }}
      >
        저장/메일발송
      </Button>
    </Box>
  );
}
