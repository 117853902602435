import { useContext } from "react";
import { useQueryClient } from "react-query";
import { Box, Switch, Typography } from "@mui/material";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";

import useHandleResponseSnackbar from "../../../../../../../hooks/useHandleResponseSnackbar";

import ResponseSnackbar from "../../../../../../../components/ResponseSnackbar";

import { FormDisabledContext } from "..";

export default function AutoRenewalSwitch({
  isAutoUpdateActivated,
  shipmentId,
}: {
  isAutoUpdateActivated: boolean | null | undefined;
  shipmentId: number;
}) {
  const queryClient = useQueryClient();

  const isDisabled = useContext(FormDisabledContext);

  const { showsSnackbar, handleSnackbarOpen, handleSnackbarClose } =
    useHandleResponseSnackbar();

  const { mutate: updateAutoTracking } = TRELLO_BID_QUERY.useUpdateAutoTracking(
    {
      shipmentId,
    }
  );

  const handleSuccess = () => {
    handleSnackbarOpen();

    queryClient.invalidateQueries(
      TRELLO_BID_QUERY_KEY_GEN.getShipmentScheduleDetail({
        shipmentId,
      })
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography>자동 갱신</Typography>

        <Switch
          checked={Boolean(isAutoUpdateActivated)}
          disabled={isDisabled || isAutoUpdateActivated === null}
          onChange={(_, v: boolean) => {
            updateAutoTracking(
              { activate: v },
              {
                onSuccess: () => handleSuccess(),
              }
            );
          }}
        />
      </Box>

      <ResponseSnackbar
        showsSnackbar={showsSnackbar}
        onSnackbarClose={handleSnackbarClose}
        title={"자동 갱신 여부가 정상적으로 반영됐습니다."}
      />
    </>
  );
}
