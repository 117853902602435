import { atom } from "jotai";

import { GET_ADMIN_NEW_BID_USER_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidUser";

const USER_TABLE_FILTER_DATA = atom<GET_ADMIN_NEW_BID_USER_REQ>({
  page: 0,
  perPage: 25,
});

export default {
  USER_TABLE_FILTER_DATA,
};
