import { useContext, useState } from "react";
import { Box } from "@mui/material";

import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import { ScheduleModifierType } from "@sellernote/_shared/src/types/forwarding/trello";

import useHandleResponseSnackbar from "../../../../../../../../hooks/useHandleResponseSnackbar";
import useHandleAlert from "../../../hooks/useHandleAlert";
import useCheckFormValidBeforeSave from "./hooks/useCheckFormValidBeforeSave";
import useHandleShipmentSchedule from "./hooks/useHandleShipmentSchedule";

import Modal from "../../../../../../../../components/Modal";
import ResponseSnackbar from "../../../../../../../../components/ResponseSnackbar";
import ValidationErrorAlert from "../../../components/ValidationErrorAlert";
import SaveButtonGroup from "../../components/SaveButtonGroup";

import { FormDisabledContext } from "../..";
import ScheduleChangeReasonModalBody from "./ScheduleChangeReasonModalBody";
import ShipLetter from "./ShipLetter";

export default function SaveAndEmailButton({
  freightType,
  highlightsButton,
  shipmentId,
  isFirstScheduleEmailSend,
  ScheduleModifierType,
  projectStatus,
  isImport,
}: {
  freightType: FreightType;
  highlightsButton: boolean;
  shipmentId: number;
  isFirstScheduleEmailSend: boolean;
  ScheduleModifierType: ScheduleModifierType | undefined;
  projectStatus: BidProjectStatus;
  isImport: boolean;
}) {
  const isDisabled = useContext(FormDisabledContext);

  const { showsAlert, setShowsAlert, alertText, handleAlertOpen } =
    useHandleAlert();

  const [isSendEmail, setIsSendEmail] = useState(false);

  const [showsScheduleChangeReasonModal, setShowsScheduleChangeReasonModal] =
    useState(false);

  const { showsSnackbar, handleSnackbarOpen, handleSnackbarClose } =
    useHandleResponseSnackbar();

  const {
    handleShipmentScheduleUpdate,
    ResponseHandlerOfRegisterShipmentSchedule,
  } = useHandleShipmentSchedule({
    isFirstScheduleEmailSend,
    isSendEmail,
    projectStatus,
    isImport,
    shipmentId,
    onSnackbarOpen: handleSnackbarOpen,
    onAlertOpen: handleAlertOpen,
  });

  const { validateFormAndSave } = useCheckFormValidBeforeSave({
    freightType,
    setShowsScheduleChangeReasonModal,
    setIsSendEmail,
    handleShipmentScheduleUpdate,
    highlightsButton,
    onAlertOpen: handleAlertOpen,
    isFirstScheduleEmailSend,
  });

  const handleScheduleChangeModalClose = () =>
    setShowsScheduleChangeReasonModal(false);

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <ShipLetter shipmentId={shipmentId} />

      <SaveButtonGroup
        saveButton={{
          onClick: () =>
            validateFormAndSave({
              sendsEmail: false,
              opensScheduleReasonModal: isFirstScheduleEmailSend,
            }),
          isDisabled,
        }}
        saveAndEmailButton={{
          onClick: () =>
            validateFormAndSave({
              sendsEmail: true,
              opensScheduleReasonModal: isFirstScheduleEmailSend,
            }),
          showsAnimation: highlightsButton,
          isDisabled,
        }}
      />

      <Modal
        isOpened={showsScheduleChangeReasonModal}
        handleClose={handleScheduleChangeModalClose}
        modalBody={
          <ScheduleChangeReasonModalBody
            shipmentId={shipmentId}
            onScheduleChangeModalClose={handleScheduleChangeModalClose}
            isFirstScheduleEmailSend={isFirstScheduleEmailSend}
            isSendEmail={isSendEmail}
            ScheduleModifierType={ScheduleModifierType}
            onSnackbarOpen={handleSnackbarOpen}
            onAlertOpen={handleAlertOpen}
          />
        }
      />

      <ValidationErrorAlert
        showsAlert={showsAlert}
        setShowsAlert={setShowsAlert}
        alertText={alertText}
      />

      <ResponseSnackbar
        showsSnackbar={showsSnackbar}
        onSnackbarClose={handleSnackbarClose}
        title={
          isSendEmail
            ? "스케줄 저장 및 메일발송이 정상 처리되었습니다."
            : "스케줄 저장이 정상 처리되었습니다."
        }
      />

      {ResponseHandlerOfRegisterShipmentSchedule}
    </Box>
  );
}
