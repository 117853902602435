import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";

export default function useHandleCargoReadyUpdate({
  shipmentId,
  onSnackbarOpen,
  isCargoReadyChanged,
  onAlertOpen,
}: {
  shipmentId: number;
  onSnackbarOpen: () => void;
  isCargoReadyChanged: boolean;
  onAlertOpen: ({ errMessage }: { errMessage: string }) => void;
}) {
  const queryClient = useQueryClient();

  const [successSnackbarTitle, setSuccessSnackbarTitle] = useState("");

  const {
    mutate: updateCargoReady,
    ResponseHandler: ResponseHandlerOfUpdateCargoReady,
  } = TRELLO_BID_QUERY.useUpdateCargoReady();

  const handleCargoReadyUpdate = useCallback(
    ({
      /** 메일 발송 여부 */
      sendsEmail,
      cargoReady,
    }: {
      sendsEmail: boolean;
      cargoReady: string | null;
    }) => {
      if (!cargoReady) {
        return onAlertOpen({ errMessage: "카고레디를 입력해주세요." });
      }

      if (!isCargoReadyChanged) {
        return onAlertOpen({ errMessage: "카고레디를 변경해주세요." });
      }

      const updateCargoReadyPayload = {
        sendEmail: sendsEmail,
        cargoReady,
        pathParams: { shipmentId },
      };

      const handleSuccess = () => {
        const snackbarMessage = sendsEmail
          ? "화물준비일정 저장 및 메일발송이 정상 처리되었습니다."
          : "화물준비일정 저장이 정상 처리되었습니다.";

        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());

        setSuccessSnackbarTitle(snackbarMessage);
        onSnackbarOpen();
      };

      updateCargoReady(updateCargoReadyPayload, { onSuccess: handleSuccess });
    },
    [
      isCargoReadyChanged,
      updateCargoReady,
      shipmentId,
      onAlertOpen,
      queryClient,
      onSnackbarOpen,
    ]
  );

  return {
    handleCargoReadyUpdate,
    successSnackbarTitle,
    ResponseHandlerOfUpdateCargoReady,
  };
}
