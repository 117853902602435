import { useAtomValue } from "jotai";

import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { checkEqualObject } from "@sellernote/_shared/src/utils/common/etc";

import { FORWARDING_ADMIN_TRELLO_ATOMS } from "../../../../../../../../../jotaiStates/trello";

export default function useCheckFormValidBeforeSave({
  freightType,
  setShowsScheduleChangeReasonModal,
  setIsSendEmail,
  handleShipmentScheduleUpdate,
  highlightsButton,
  onAlertOpen,
  isFirstScheduleEmailSend,
}: {
  freightType: FreightType;
  setShowsScheduleChangeReasonModal: (val: boolean) => void;
  setIsSendEmail: (val: boolean) => void;
  handleShipmentScheduleUpdate: ({
    isRegisterScheduleSendsEmail,
  }: {
    isRegisterScheduleSendsEmail?: boolean;
  }) => void;
  highlightsButton: boolean;
  onAlertOpen: ({ errMessage }: { errMessage: string }) => void;
  isFirstScheduleEmailSend: boolean;
}) {
  const shipmentScheduleList = useAtomValue(
    FORWARDING_ADMIN_TRELLO_ATOMS.SHIPMENT_SCHEDULE_LIST
  );

  const prevShipmentScheduleList = useAtomValue(
    FORWARDING_ADMIN_TRELLO_ATOMS.PREVIOUS_SHIPMENT_SCHEDULE_LIST
  );

  const doesArrayHaveSameObject = checkEqualObject(
    shipmentScheduleList,
    prevShipmentScheduleList
  );

  const validateFormAndSave = ({
    sendsEmail,
    opensScheduleReasonModal,
  }: {
    sendsEmail: boolean;
    opensScheduleReasonModal: boolean;
  }) => {
    /** 메일 발송 여부 상태 변경 */
    setIsSendEmail(sendsEmail);

    const lastIndex = shipmentScheduleList.length - 1;

    /** 구간에 모선이 존재하지 않는 경우 Alert (항공은 제외) */
    if (
      !shipmentScheduleList.every((form) => Boolean(form.shipName)) &&
      freightType !== "AIR"
    ) {
      onAlertOpen({ errMessage: "모선이 공란인 구간이 있는지 확인해주세요." });
      return;
    }

    /** 첫 구간의 출발 일정이 없으면 Alert */
    if (!(shipmentScheduleList[0].ETD || shipmentScheduleList[0].ATD)) {
      onAlertOpen({ errMessage: "첫 구간의 출발 일정을 입력해주세요." });
      return;
    }

    /** 각 구간에 도착 일정이 없으면 Alert */
    if (!shipmentScheduleList.every((form) => Boolean(form.ETA || form.ATA))) {
      onAlertOpen({ errMessage: "모든 구간의 도착 일정을 입력해주세요." });
      return;
    }

    /**
     * 전 구간의 도착지 / 후 구간의 출발지가 동일한지 확인
     * 폼이 하나밖에 없을 땐 검증 X
     */
    if (
      shipmentScheduleList.length > 1 &&
      !shipmentScheduleList.every((form, index) => {
        return (
          index === lastIndex ||
          (Boolean(form?.arrivalPort.id) &&
            Boolean(shipmentScheduleList[index + 1]?.departurePort.id) &&
            form?.arrivalPort.id ===
              shipmentScheduleList[index + 1]?.departurePort.id)
        );
      })
    ) {
      onAlertOpen({
        errMessage:
          "이전 구간의 도착지와 다음 구간의 출발지가 동일한지 확인해주세요.",
      });
      return;
    }

    /** 수정사항이 없는 경우 Alert, 스케줄 입력의 경우 Validation 진행 X */
    if (
      doesArrayHaveSameObject &&
      !highlightsButton &&
      isFirstScheduleEmailSend
    ) {
      onAlertOpen({
        errMessage: "수정사항이 없습니다.",
      });
      return;
    }

    /** 유효성 검증에 통과하고, 스케줄 변경의 경우 변경 사유 입력 모달을 노출 */
    if (opensScheduleReasonModal) {
      setShowsScheduleChangeReasonModal(true);

      return;
    }

    /** 스케줄 입력은 별도 모달 없이 바로 동작하기 때문에 setState로 의도한 값 보장이 안 됨. props 전달 처리 */
    handleShipmentScheduleUpdate({ isRegisterScheduleSendsEmail: sendsEmail });
  };

  return {
    validateFormAndSave,
  };
}
