/**
 * @example
 * ET가 있을땐 ET, AT가 있을 땐 AT를 노출
 * ET, AT가 둘 다 있을 땐 AT를 노출
 *
 * @param ET Estimated Time (ETD, ATD)
 * @param AT Actual Time (ATD, ATA)
 *
 * @see useUpdateShipmentSchedule 스케줄 변경 API 요청에 필요한 데이터를 가공
 * @see Departure.tsx ETD, ATD 노출 조건 처리
 * @see Arrival.tsx ETA, ATA 노출 조건 처리
 */
function getScheduleTime({
  ET,
  AT,
  isDeparture,
}: {
  ET: string | null;
  AT: string | null;
  isDeparture?: boolean;
}): {
  type: string | null;
  date: string | null;
} {
  /** ET, AT의 값이 둘 다 null일 때 */
  if (!ET && !AT) {
    return { type: null, date: null };
  }

  /** ET, AT의 값이 둘 다 존재할 때 */
  if (ET && AT) {
    return {
      type: isDeparture ? "ATD" : "ATA",
      date: AT,
    };
  }

  const type = isDeparture ? (AT ? "ATD" : "ETD") : AT ? "ATA" : "ETA";
  const date = ET || AT;

  return { type, date };
}

export default getScheduleTime;
