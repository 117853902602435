import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

export default function AutoRenewalRadio({
  keepsAutoRenewal,
  onAutoRenewalUse,
}: {
  keepsAutoRenewal: boolean;
  onAutoRenewalUse: (val: boolean) => void;
}) {
  return (
    <Box style={{ marginTop: 40 }}>
      <Typography color="error">
        **마린트래픽 또는 선사에서 입력된 데이터가 삭제/수정되었습니다.
        <br />
        앞으로 자동갱신을 중단하시겠습니까?
      </Typography>

      <RadioGroup
        row
        aria-labelledby="auto-renewal-select-radio"
        value={keepsAutoRenewal ? "true" : "false"}
        onChange={(e) => onAutoRenewalUse(e.target.value === "true")}
      >
        <FormControlLabel value="false" control={<Radio />} label="중단" />
        <FormControlLabel value="true" control={<Radio />} label="유지" />
      </RadioGroup>
    </Box>
  );
}
