import { useContext } from "react";

import useHandleResponseSnackbar from "../../../../../../../hooks/useHandleResponseSnackbar";
import useHandleAlert from "../../hooks/useHandleAlert";

import ResponseSnackbar from "../../../../../../../components/ResponseSnackbar";
import ValidationErrorAlert from "../../components/ValidationErrorAlert";
import SaveButtonGroup from "../components/SaveButtonGroup";

import { FormDisabledContext } from "..";
import useHandleCargoReadyUpdate from "./useHandleCargoReadyUpdate";

export default function SaveAndEmailButton({
  cargoReady,
  shipmentId,
  isCargoReadyChanged,
}: {
  cargoReady: string | null;
  shipmentId: number;
  isCargoReadyChanged: boolean;
}) {
  const isDisabled = useContext(FormDisabledContext);

  const { showsAlert, setShowsAlert, alertText, handleAlertOpen } =
    useHandleAlert();

  const { showsSnackbar, handleSnackbarOpen, handleSnackbarClose } =
    useHandleResponseSnackbar();

  const {
    handleCargoReadyUpdate,
    successSnackbarTitle,
    ResponseHandlerOfUpdateCargoReady,
  } = useHandleCargoReadyUpdate({
    shipmentId,
    onSnackbarOpen: handleSnackbarOpen,
    isCargoReadyChanged,
    onAlertOpen: handleAlertOpen,
  });

  return (
    <>
      <SaveButtonGroup
        saveButton={{
          onClick: () =>
            handleCargoReadyUpdate({ sendsEmail: false, cargoReady }),
          isDisabled,
        }}
        saveAndEmailButton={{
          onClick: () =>
            handleCargoReadyUpdate({ sendsEmail: true, cargoReady }),
          isDisabled,
        }}
      />

      <ResponseSnackbar
        showsSnackbar={showsSnackbar}
        onSnackbarClose={handleSnackbarClose}
        title={successSnackbarTitle}
      />

      <ValidationErrorAlert
        showsAlert={showsAlert}
        setShowsAlert={setShowsAlert}
        alertText={alertText}
      />

      {ResponseHandlerOfUpdateCargoReady}
    </>
  );
}
