import { Box, Typography } from "@mui/material";
import { useSetAtom } from "jotai";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";

import { FORWARDING_ADMIN_TRELLO_ATOMS } from "../../../../../../../jotaiStates/trello";
import AutoRenewalSwitch from "./AutoRenewalSwitch";
import SaveAndEmailButton from "./SaveAndEmailButton";
import ScheduleInput from "./ScheduleInput";

export default function ScheduleForm({
  shipmentId,
  freightType,
  isFirstScheduleEmailSend,
  projectStatus,
  isConsolOrLCL,
  isImport,
}: {
  shipmentId: number;
  freightType: FreightType;
  isFirstScheduleEmailSend: boolean;
  projectStatus: BidProjectStatus;
  isConsolOrLCL: boolean;
  isImport: boolean;
}) {
  const setShipmentScheduleList = useSetAtom(
    FORWARDING_ADMIN_TRELLO_ATOMS.SHIPMENT_SCHEDULE_LIST
  );

  const setPrevShipmentScheduleList = useSetAtom(
    FORWARDING_ADMIN_TRELLO_ATOMS.PREVIOUS_SHIPMENT_SCHEDULE_LIST
  );

  const { data: shipmentScheduleDetail } =
    TRELLO_BID_QUERY.useGetShipmentScheduleDetail({
      shipmentId,
      onSuccess: (data) => {
        setShipmentScheduleList(data.routes);
        setPrevShipmentScheduleList(data.routes);
      },
    });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "12px",
        border: "1px solid #7e7e7e",
        padding: "20px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>고객에게 안내할 스케줄을 입력하세요.</Typography>

        <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <AutoRenewalSwitch
            isAutoUpdateActivated={
              shipmentScheduleDetail?.isAutoUpdateActivated
            }
            shipmentId={shipmentId}
          />

          <Typography>
            최근 수정자:{" "}
            <span style={{ fontWeight: "bold" }}>
              {shipmentScheduleDetail?.lastUpdatedBy}
            </span>
          </Typography>
        </Box>
      </Box>

      <ScheduleInput
        shipmentScheduleDetail={shipmentScheduleDetail}
        freightType={freightType}
        isConsolOrLCL={isConsolOrLCL}
      />

      <SaveAndEmailButton
        freightType={freightType}
        /** 선사/마린트래픽에 의해 변경된 스케줄이 있을 때 버튼을 강조  */
        highlightsButton={Boolean(
          shipmentScheduleDetail?.scheduleUpdatedItems.length
        )}
        shipmentId={shipmentId}
        isFirstScheduleEmailSend={isFirstScheduleEmailSend}
        ScheduleModifierType={shipmentScheduleDetail?.scheduleChannel}
        projectStatus={projectStatus}
        isImport={isImport}
      />
    </Box>
  );
}
